import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bench Press 4-4-4-4\\@85% 1RM`}</p>
    <p>{`Strict Pullups (weighted if possible) 4-4-4-4\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“JT”`}</strong></p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`HSPU’s`}</p>
    <p>{`Ring Dips`}</p>
    <p>{`Pushups (yes, always hand release)`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts February 23rd! Sign up now at:
Games.crossfit.com.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts March 4th. Email Eric at
fallscitystrength\\@gmail for more info.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      